import PropTypes from "prop-types"
import React from "react"
import './footer.css';
import Phone from "./phone"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Footer = ({ siteTitle, version, email, phone, instagramUrl, twitterUrl }) => (
  <footer>
    <ul className="social">
      <li>
        <a href={`mailto:${email}`} rel="nofollow" className="email-link">
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <span itemProp="email">{email}</span>
        </a>
      </li>
      <li>
        <a href={`tel:${phone}`} rel="nofollow" className="phone-link">
          <FontAwesomeIcon icon={faPhone} className="icon" />
          <Phone phone={phone} />
        </a>
      </li>
      <li>
        <a href={instagramUrl} target="_blank" rel="noopener noreferrer" className="instagram-link">
          <FontAwesomeIcon icon={faInstagram} className="icon" />
          <span>Instagram</span>
        </a>
      </li>
      <li>
        <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="twitter-link">
          <FontAwesomeIcon icon={faTwitter} className="icon" />
          <span>Twitter</span>
        </a>
      </li>
    </ul>

    <p className="lower-footer">
      <span className="made-by">
        Made by <a href="https://alexrechetov.com" target="_blank" rel="noopener noreferrer">Alex Rechetov</a>
      </span>
      <span className="copyright">
        © {new Date().getFullYear()} {siteTitle} Inc. All Rights Reserved
      </span>
    </p>
    <p className="hidden">Version: {version}</p>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  version: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  instagramUrl: PropTypes.string,
  twitterUrl: PropTypes.string
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
