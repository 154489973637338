/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteLayoutQuery {
      site {
        siteMetadata {
          title,
          version,
          email,
          phone,
          instagramUrl,
          twitterUrl
        }
      }
    }
  `)

  const updateHeaderStyle = () => {
    const headerPadding = Math.max(0, 30 - (window.scrollY / 5));
    const shadowSize = (30 - headerPadding) / 7.5;
    document.getElementById('header').style.padding = `${headerPadding}px`;
    document.getElementById('header').style.boxShadow = `0px ${shadowSize}px ${shadowSize}px rgba(50, 50, 50, 0.2)`;
  };

  const updateWelcomeStyle = () => {
    document.getElementById('welcome').style.opacity = 1 - (window.scrollY / window.innerHeight);
    document.getElementById('welcomeBlock').style.transform = `scale(${1 - (window.scrollY / 1500)})`;
  };

  const handleWindowScroll = () => {
    updateHeaderStyle();
    updateWelcomeStyle();
  };

  if (typeof window !== "undefined") {
    // Enable smooth scrolling for all links to paths with "#"
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]');

    // Toggle menu position
    window.onscroll = handleWindowScroll;
    window.onload = handleWindowScroll;
    window.onresize = handleWindowScroll;
  }

  const siteMetadata = data.site.siteMetadata;

  return (
    <>
      <Header
        siteTitle={siteMetadata.title}
        instagramUrl={siteMetadata.instagramUrl}
        twitterUrl={siteMetadata.twitterUrl}
      />

      <main>{children}</main>

      <Footer
        siteTitle={siteMetadata.title}
        version={siteMetadata.version}
        email={siteMetadata.email}
        phone={siteMetadata.phone}
        instagramUrl={siteMetadata.instagramUrl}
        twitterUrl={siteMetadata.twitterUrl}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
