import PropTypes from "prop-types"
import React from "react"
import './footer.css';

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

const Phone = ({ phone }) => {
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <span itemProp="telephone">{formattedPhone}</span>
  )
}

Phone.propTypes = {
  phone: PropTypes.string
}

export default Phone
