import React from "react"
import './header.css';
import LogoCompact from "../images/logo-compact.svg"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"

const menuItems = ['about', 'projects', 'contact'];

const capitalize = (str) => {
  if (typeof str !== 'string' || str.length === 0) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Header = ({ instagramUrl, twitterUrl }) => (
  <header id="header">
    <menu>
      <ul className="header-menu">
        <li>
          <Link to="/#welcome" className="link-logo" aria-label="Scroll to welcome section">
            <LogoCompact className="header-logo" />
          </Link>
        </li>
        {menuItems.map((menuItem) => (
          <li key={menuItem}>
            <Link to={`/#${menuItem}`} className="menu-item">{capitalize(menuItem)}</Link>
          </li>
        ))}
      </ul>

      <ul className="header-social">
        <li>
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer" className="instagram-link" title="Instagram">
            <FontAwesomeIcon icon={faInstagram} className="icon" />
          </a>
        </li>
        <li>
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer" className="twitter-link" title="Twitter">
            <FontAwesomeIcon icon={faTwitter} className="icon" />
          </a>
        </li>
      </ul>
    </menu>
  </header>
)

Header.propTypes = {
  instagramUrl: PropTypes.string,
  twitterUrl: PropTypes.string
}

export default Header
